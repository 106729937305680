



















import { BlTextAv } from '@bukalapak/bazaar-mweb'
import Vue from 'vue'
import LandingProductCard from './card.vue'
import CategoryList from './CategoryList.vue'
import { MainGlobalEvents } from '~/constants/pages/main-global-events'
import type { CategoryProduct } from '~/types/component/landing-product'

export default Vue.extend({
  name: 'LandingProduct',
  components: {
    CategoryList,
    BlTextAv,
    LandingProductCard,
  },
  data() {
    return {
      products: [
        {
          name: 'Pasir Bangka Kasar 7m3',
          image: require('~/assets/images/landing-page/product/Pasir Bangka Kasar 7m3.jpeg'),
        },
        {
          name: 'Bata Ringan Alfacon 7,5cm/10cm',
          image: require('~/assets/images/landing-page/product/Bata Ringan Alfacon 7,5cm_10cm.jpeg'),
        },
        {
          name: 'Batu Split 7m3',
          image: require('~/assets/images/landing-page/product/Batu Split 7m3.jpeg'),
        },
        {
          name: 'Mortar Utama Weber Weberseal Silicone Glass Sealant Kaca & Akuarium 275ml - Transparant',
          image: require('~/assets/images/landing-page/product/Mortar Utama Weber Weberseal Silicone Glass Sealant Kaca & Akuarium 275ml - Transparant.jpg'),
        },
        {
          name: `Gypsum Aplus 1200x2400x9mm '100s`,
          image: require(`~/assets/images/landing-page/product/Gypsum Aplus 1200x2400x9mm '100s.jpg`),
        },
        {
          name: 'RYU Mata Potong Keramik 110x10x1.2mm (4") - AC-DD180',
          image: require('~/assets/images/landing-page/product/RYU Mata Potong Keramik 110x10x1.2mm (4_) - AC-DD180.jpg'),
        },
        {
          name: `Hannochs NOVA 12W CDL '6s`,
          image: require(`~/assets/images/landing-page/product/Hannochs NOVA 12W CDL '6s.png`),
        },
        {
          name: 'Uticon ST-118 Stop Kontak 1 Lb',
          image: require('~/assets/images/landing-page/product/Uticon ST-118 Stop Kontak 1 Lb.jpg'),
        },
        {
          name: 'Eterna NYM 2x1.5 @50m',
          image: require('~/assets/images/landing-page/product/Eterna NYM 2x1.5 @50m.PNG'),
        },
        {
          name: 'Semen Tiga Roda 40kg (Proyek Only)',
          image: require('~/assets/images/landing-page/product/Semen Tiga Roda 40kg (Proyek Only).jpg'),
        },
        {
          name: 'Semen Tiga Roda 50kg (Proyek Only)',
          image: require('~/assets/images/landing-page/product/Semen Tiga Roda 50kg (Proyek Only).jpg'),
        },
        {
          name: 'Broco 226 Fitting Kombinasi',
          image: require('~/assets/images/landing-page/product/Broco 226 Fitting Kombinasi.jpg'),
        },
      ],
      imagePlaceholder: require('~/assets/images/loader-thumbnail.svg'),
    }
  },
  computed: {
    isMobile(): boolean {
      return this.$viewport.match('mobile').valueOf()
    },
    computedProducts(): CategoryProduct[] {
      return this.isMobile ? this.products.slice(0, 10) : this.products
    },
  },
  methods: {
    handleDownload() {
      this.$nuxt.$emit(MainGlobalEvents.HEADER_DOWNLOAD_EVENT)
    },
  },
})
