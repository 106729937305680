































import { BlCarouselMv, BlCarouselSlideMv } from '@bukalapak/bazaar-dweb'
import { BlFlexContainerMv, BlFlexItemAv, BlTextAv } from '@bukalapak/bazaar-mweb'
import VLazyImage from 'v-lazy-image/v2'
import Vue from 'vue'

export default Vue.extend({
  name: 'LandingTutorials',
  components: {
    BlFlexContainerMv,
    BlFlexItemAv,
    BlTextAv,
    BlCarouselMv,
    BlCarouselSlideMv,
    VLazyImage,
  },
  data() {
    return {
      tutorials: [
        {
          title: 'Buka aplikasi dan cari barang yang kamu mau.',
          image: require('~/assets/images/landing-page/tutorials/tutorial-1.png'),
        },
        {
          title: 'Bisa juga cari berdasarkan kategori barang.',
          image: require('~/assets/images/landing-page/tutorials/tutorial-2.png'),
        },
        {
          title: 'Klik Beli pada barang yang kamu mau.',
          image: require('~/assets/images/landing-page/tutorials/tutorial-3.png'),
        },
        {
          title: 'Setelah itu, klik Lihat keranjang',
          image: require('~/assets/images/landing-page/tutorials/tutorial-4.png'),
        },
        {
          title: 'Lalu cek barangnya lagi, jika sudah sesuai klik Beli',
          image: require('~/assets/images/landing-page/tutorials/tutorial-5.png'),
        },
        {
          title: 'Setelah pilih metode pembayaran, klik pesan sekarang.',
          image: require('~/assets/images/landing-page/tutorials/tutorial-6.png'),
        },
        {
          title: 'Selesai deh! Tunggu barangnya sampai ke alamatmu, mudahkan!',
          image: require('~/assets/images/landing-page/tutorials/tutorial-7.png'),
        },
      ],
      imagePlaceholder: require('~/assets/images/loader-thumbnail.svg'),
    }
  },
})
