

































import { BlModalMv } from '@bukalapak/bazaar-dweb'
import { BlSheetMv } from '@bukalapak/bazaar-mweb'
import Vue from 'vue'
import SectionCategories from '../SectionCategories.vue'

export default Vue.extend({
  components: {
    BlModalMv,
    BlSheetMv,
    SectionCategories,
  },
  props: {
    screen: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isDetailPopupOpens: false,
    }
  },
  computed: {
    isMobile(): boolean {
      return this.$viewport.match('mobile').valueOf()
    },
  },
  watch: {
    isDetailPopupOpens(active) {
      if (!active) {
        this.trackClickModalClose()
      }
    },
  },
  methods: {
    onExpandCategory() {
      this.isDetailPopupOpens = true
    },
    trackClickModalClose() {
      this.$tracker.track('app_action', {
        screen_name: 'Homepage_Screen',
        section_name: 'Category_Popup',
        action_type: 'click',
        action_source: 'Close_Button',
        action_attribute: JSON.stringify({}),
      })
    },
  },
})
