




import Vue from 'vue'
import { seoContents } from './seo-contents'
import SeoContent from '~/components/molecules/SeoContent.vue'

export default Vue.extend({
  name: 'LandingSeo',
  components: {
    SeoContent,
  },
  data() {
    return {
      contents: seoContents,
    }
  },
})
