















import '@bukalapak/bazaar-mweb/dist/bazaar.css'
import Vue from 'vue'
import { MetaInfo } from 'vue-meta'
import Banner from '~/components/organisms/LandingPage/Banner/index.vue'
import Brand from '~/components/organisms/LandingPage/Brand/index.vue'
import Download from '~/components/organisms/LandingPage/Download/index.vue'
import LandingSeo from '~/components/organisms/LandingPage/LandingSeo/index.vue'
import Product from '~/components/organisms/LandingPage/Product/index.vue'
import Testimonials from '~/components/organisms/LandingPage/Testimonials/index.vue'
import Tutorials from '~/components/organisms/LandingPage/Tutorials/index.vue'
import Usp from '~/components/organisms/LandingPage/Usp/index.vue'
import { MainGlobalEvents } from '~/constants/pages/main-global-events'

export default Vue.extend({
  name: 'IndexPage',
  auth: false,
  components: {
    Banner,
    Brand,
    Usp,
    Product,
    Tutorials,
    Testimonials,
    Download,
    LandingSeo,
  },
  layout: 'MainLayout',
  data() {
    const defaultValueTracker = function () {
      return {
        started: 0,
        finished: 0,
      }
    }
    return {
      section: {
        header: defaultValueTracker(),
        brandUsp: defaultValueTracker(),
        categoryProduct: defaultValueTracker(),
        tutorials: defaultValueTracker(),
        testimonials: defaultValueTracker(),
        download: defaultValueTracker(),
        seo: defaultValueTracker(),
        footer: defaultValueTracker(),
      },
    }
  },
  head(): MetaInfo {
    const url = `https://www.${this.$nuxt.context.$config.cookie.domain}`
    return {
      meta: [{ property: 'og:url', content: url }],
      link: [{ rel: 'canonical', href: url }],
    }
  },
  mounted() {
    this.$nuxt.$emit(MainGlobalEvents.HEADER_CONFIG, { screen: 'Homepage_Screen' })
    this.$nuxt.$emit(MainGlobalEvents.SCREEN_NAME, 'Homepage_Screen')
    this.trackVisit()
    if (process.client) {
      window.addEventListener('scroll', this.trackerEvent)
    }
  },
  beforeDestroy() {
    if (process.client) {
      window.removeEventListener('scroll', this.trackerEvent)
    }
  },
  methods: {
    trackVisit() {
      this.$tracker.track('app_screen_view', {
        screen_name: 'Homepage_Screen',
        screen_url: this.$route.fullPath,
        screen_referrer: document.referrer,
        screen_parameter: '',
        screen_status: '',
        screen_attribute: JSON.stringify({}),
      })
    },
    trackerEvent() {
      this.trackSectionVisited('header', 'Header_Section', 'c-banner')
      this.trackSectionVisited('brandUsp', 'VendorListAndBenefits_Section', 'c-brand-usp')
      this.trackSectionVisited('categoryProduct', 'AvailableProduct_Section', 'c-product')
      this.trackSectionVisited('tutorials', 'EaseOfUse_Section', 'c-tutorial')
      this.trackSectionVisited('testimonials', 'Testimonial_Section', 'c-testimonials')
      this.trackSectionVisited('download', 'DownloadApp_Section', 'c-download')
      this.trackSectionVisited('seo', 'ReadDetail_Section', 'c-seo')
      this.trackSectionVisited('footer', 'Footer_Section', 'c-footer__wrapper')
    },
    trackImpression(sectionName: string, duration: number) {
      this.$tracker.track('app_impression', {
        screen_name: 'Homepage_Screen',
        scroll_type: 'swipe-up',
        section_name: sectionName,
        item_name: '',
        duration,
        impression_attribute: JSON.stringify({}),
      })
    },
    trackSectionVisited(name: string, sectionName: string, className: string) {
      const { innerHeight } = window
      const elementComponent = document.querySelector(`.${className}`)
      const element = (elementComponent && elementComponent.getBoundingClientRect()) || null

      if (element && element.top < innerHeight && !this.section[name].started) {
        this.section[name].started = new Date().getTime()
      }

      if (element && element.bottom < innerHeight && !this.section[name].finished) {
        this.section[name].finished = new Date().getTime()
        const duration = this.section[name].finished - this.section[name].started
        this.trackImpression(sectionName, duration)
      }
    },
  },
})
