import { SeoContents, SeoContentType } from '~/types/model/seo'

export const seoContents: SeoContents = {
  title: 'Macam-Macam Jenis & Harga Bahan Bangunan',
  list: [
    {
      type: SeoContentType.TEXT,
      value:
        'Salah satu bisnis menjanjikan dan membawa banyak untung adalah membuka usaha toko bangunan. Namun, sebelum memulai bisnis ini, ada banyak hal yang harus diketahui, mulai dari jenis-jenis bahan bangunan, harga bahan bangunan, dan informasi lainnya.',
    },
    {
      type: SeoContentType.TEXT,
      value:
        'Tidak hanya berbicara mengenai produk-produk yang akan dijual, sebagai seseorang yang akan memulai bisnis baru, maka harus tahu mengenai dana yang dibutuhkan, pemasok, hingga strategi bisnis yang akan dijalankan agar bisa mendatangkan banyak pembeli.',
    },
    {
      type: SeoContentType.TEXT,
      value:
        'Maka dari itu, simak beberapa informasi yang akan sangat berguna buat kamu sebelum memulai usaha toko bahan bangunan berikut ini!',
    },
    {
      type: SeoContentType.TITLE,
      value: 'Daftar Bahan yang Termasuk Bahan Bangunan',
    },
    {
      type: SeoContentType.TEXT,
      value:
        'Memulai sebuah usaha, wajib untuk tahu mengenai produk yang akan dijual, termasuk buka toko bahan bangunan. Di bawah ini ada beberapa jenis bahan bangunan yang dipakai untuk membangun rumah hingga gedung.',
    },
    {
      type: SeoContentType.OL,
      value: [
        {
          title: 'Batu Kali',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Bahan bangunan yang pertama adalah batu kali yang biasa dipakai untuk membuat pondasi bangunan. Baik untuk bangunan besar atau rumah modern yang ukurannya tidak terlalu luas dan tinggi, fungsi batu kali atau biasa disebut juga dengan batu alam sangat penting.',
            },
            {
              type: SeoContentType.TEXT,
              value:
                'Tidak hanya bisa dipakai untuk fondasi bangunan saja, kini beberapa rumah menghadirkan batu alam untuk estetika tembok, sehingga terkesan lebih natural.',
            },
          ],
        },
        {
          title: 'Batu Bata',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Pasti kamu sudah paham apa fungsi batu bata. Bahan bangunan ini dipakai untuk membuat dinding sebab memiliki sifat yang tahan lama dan kokoh. Namun, kini fungsi bata merah mulai digantikan dengan batako karena lebih murah. Untuk ketahanannya, bata merah dinilai lebih kuat daripada batako.',
            },
            {
              type: SeoContentType.TEXT,
              value:
                'Sama halnya dengan batu kali, beberapa rumah zaman sekarang banyak ditemui menggunakan bahan bangunan ini sekaligus untuk meningkatkan estetika, sehingga dibiarkan terekspos. Biasanya, yang menonjolkan batu bata di dindingnya adalah bangunan gaya industrial, seperti kafe.',
            },
          ],
        },
        {
          title: 'Pasir',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Selanjutnya ada pasir yang merupakan salah satu material alami untuk konstruksi. Biasanya, pasir akan digunakan untuk campuran semen. Tidak hanya sebagai perekat bata, dengan tekstur butiran tajamnya, pasir juga dipakai untuk pondasi.',
            },
          ],
        },
        {
          title: 'Kerikil',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Selain pasir, dalam campuran semen untuk membuat bangunan juga diberi sedikit kerikil. Bahan bangunan ini berfungsi untuk mengisi rongga kosong.',
            },
          ],
        },
        {
          title: 'Kayu',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Mungkin kamu sudah tidak asing dengan bahan bangunan yang satu ini. Kayu adalah salah satu bahan bangunan yang berfungsi untuk membuat rangka atap hingga perabotan rumah. Dalam bangunan sendiri, wajib untuk memilih kayu yang kokoh. Tidak hanya itu saja, kayu juga bisa menambah estetika bangunan.',
            },
          ],
        },
        {
          title: 'Genting',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Saat ini, jenis-jenis genting bermacam-macam dengan bahan yang berbeda-beda. Namun, biasanya genting yang dipakai untuk rumah-rumah adalah dari tanah liat merah. Genting tersebut dirasa lebih kuat dan memberi kesan dingin untuk rumah.',
            },
          ],
        },
        {
          title: 'Semen',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Semen juga menjadi bahan bangunan harus ada di toko bangunan. Ada banyak fungsi semen untuk bangunan, mulai dari perekat batu bata di dinding, perekat keramik, dan lainnya. Jika tidak ada semen, makan bahan bangunan tidak bisa menyatu satu sama lain.',
            },
          ],
        },
        {
          title: 'Kaca',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Bahan bangunan selanjutnya adalah kaca, yang biasa dipakai untuk jendela. Selain itu, kaca juga bisa difungsikan sebagai pintu dan dekorasi rumah.',
            },
          ],
        },
        {
          title: 'Baja dan besi',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Agar bangunan lebih kokoh dan kuat, maka ditambah baja atau besi di atas fondasi untuk penguat dinding. Baja biasanya dipasang vertikal di sudut-sudut dinding. Baja juga berfungsi untuk penguat atap.',
            },
          ],
        },
        {
          title: 'Paku',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Keberadaan paku bisa menggabungkan beberapa bahan bangunan, seperti kayu, triplek, dan lainnya. Paku memiliki banyak ukuran tergantung ketebalan bahan yang akan direkatkan.',
            },
          ],
        },
        {
          title: 'Pipa',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Kebutuhan manusia yang tidak bisa jauh dari keberadaan air membuat pipa menjadi bahan bangunan penting. Fungsi pipa adalah untuk menyalurkan air dari sumber ke keran. Selain itu, pipa juga berguna untuk menyalurkan air dari talang ke tanah agar tidak terciprat ke mana-mana ketika hujan.',
            },
          ],
        },
        {
          title: 'Keramik',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Tidak semua toko bahan bangunan menyediakan keramik. Fungsi dari bahan bangunan ini adalah untuk menutupi alas agar lebih mudah dibersihkan. Keramik yang memiliki banyak warna dan motif juga bisa menambah estetika ruangan.',
            },
          ],
        },
      ],
    },
    {
      type: SeoContentType.TITLE,
      value: 'Ciri-ciri Bahan Bangunan Berkualitas',
    },
    {
      type: SeoContentType.TEXT,
      value:
        'Sebagai pemilik usaha toko bahan bangunan, penting untuk mengetahui ciri-ciri bahan bangunan berkualitas, ini juga bisa membuat pembeli tetap mencari bahan bangunan di toko kamu. Nah, berikut ini beberapa ciri-ciri bahan bangunan yang berkualitas.',
    },
    {
      type: SeoContentType.OL,
      value: [
        {
          title: 'Batu Kali',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'karena batu kali biasa dipakai untuk pondasi, maka pilih yang padat dan tidak berongga. Sebab, batu kali yang berongga akan lebih mudah retak.',
            },
          ],
        },
        {
          title: 'Batu Bata',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'bata merah yang baik untuk bangunan adalah yang berukuran sedang dan keras, sehingga tidak mudah patah.',
            },
          ],
        },
        {
          title: 'Pasir',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'pasir yang bagus adalah yang bersih dan tidak ada kandungan lumpur. Keberadaan lumpur di pasir bisa berpengaruh saat pengecoran, menyebabkan semen yang dibutuhkan jadi lebih banyak.',
            },
          ],
        },
        {
          title: 'Kerikil',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'untuk kerikil yang baik adalah yang tidak berongga dan ringan melainkan yang berasal dari pecahan batu besar yang cukup masih berat.',
            },
          ],
        },
        {
          title: 'Kayu',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'ada banyak jenis kayu yang bisa dipakai untuk konstruksi bangunan. Kayu yang bagus adalah yang kandungan airnya di bawah 20% dan pastikan sudah benar-benar kering.',
            },
          ],
        },
        {
          title: 'Genting',
          value: [
            {
              type: SeoContentType.TEXT,
              value: 'kamu bisa mencari genteng yang kering dan tidak mudah lapuk ketika dipegang.',
            },
          ],
        },
        {
          title: 'Semen',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'sebenarnya, hampir semua semen di pasaran memiliki harga dan kualitas yang sama, maka pastikan semen yang dijual di toko bahan bangunanmu adalah semen dari merek terkenal yang sudah teruji secara SNI.',
            },
          ],
        },
        {
          title: 'Kaca',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'bahan bangunan yang satu ini memiliki ketebalan yang berbeda-beda tergantung jenis dan fungsinya. Selain itu, jenis kaca pun juga beraneka ragam.',
            },
          ],
        },
        {
          title: 'Baja dan besi',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'berguna untuk menahan bangunan agar kuat dan tidak roboh, maka besi atau baja harus memiliki kualitas dan struktur yang baik.',
            },
          ],
        },
        {
          title: 'Paku',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'benda satu ini memiliki ukuran yang sangat banyak, mulai dari yang terkecil hingga panjang. Paku yang baik adalah yang tidak mudah bengkok atau pecah namun runcing di bawahnya.',
            },
          ],
        },
        {
          title: 'Pipa',
          value: [
            {
              type: SeoContentType.TEXT,
              value: 'tentu saja, pipa yang berkualitas adalah yang tidak bocor, tidak mudah pecah, dan tahan jamur.',
            },
          ],
        },
        {
          title: 'Keramik',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'jenis keramik sangat bermacam-macam tergantung dengan harga dan kebutuhannya, misalnya untuk keramik rumah dan kamar mandi.',
            },
          ],
        },
      ],
    },
    {
      type: SeoContentType.TITLE,
      value: 'Tips Mendapatkan Bahan Bangunan Berkualitas',
    },
    {
      type: SeoContentType.TEXT,
      value:
        'Lalu, bagaimana cara mendapatkan bahan bangunan berkualitas? Sebenarnya ada tips yang bisa kamu lakukan, seperti memegang pasir secara langsung. Artinya, kamu harus melihat secara langsung untuk bahan bangunan yang akan dipilih.',
    },
    {
      type: SeoContentType.OL,
      value: [
        {
          title: 'Mendapatkan Batu Kali yang Bagus untuk Pondasi',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Batu untuk pondasi yang berkualitas biasanya diambil dari daerah pegunungan, ini adalah batu dari material vulkanik. Batu kali yang bagus adalah yang berasal dari batu besar kemudian dibelah, karena batu ini bisa saling tertaut dengan kuat.',
            },
          ],
        },
        {
          title: 'Memilih Batu Bata Berkualitas',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Ketok permukaan batu bata dengan tanganmu, jika suara yang muncul nyaring maka batu bata tersebut cukup berkualitas. Lalu, coba angkat batu bata, apabila ringan artinya pori-pori batu bata padat dan nantinya batu bata itu tidak akan mudah pecah.',
            },
            {
              type: SeoContentType.TEXT,
              value:
                'Kamu juga bisa melakukan tes sederhana dengan merendam batu bata 24 jam dan lihat apa yang terjadi. Jika tidak ada perubahan bentuk dan berat maka batu bata itu memiliki kualitas yang bagus.',
            },
          ],
        },
        {
          title: 'Pilih Pasir yang Bersih',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Kamu bisa mengambil pasir dengan tanganmu dan genggam dengan kuat. Jika kamu tidak merasa ada yang menusuk, maka pasir tersebut bisa digolongkan pasir yang bagus.',
            },
            {
              type: SeoContentType.TEXT,
              value:
                'Kemudian, pastikan pasir tidak menggumpal, sehingga setelah menggenggam buka tanganmu lagi dan lihat bagaimana keadaan pasir. Seperti yang disebutkan sebelumnya untuk tidak memilih pasir yang bercampur lumpur, pasir yang menggumpal tandanya bercampur lumpur.',
            },
            {
              type: SeoContentType.TEXT,
              value:
                'Biasanya, pasir yang bagus diambil dari sungai, sebab pasir tersebut berasal dari letusan gunung berapi.',
            },
          ],
        },
        {
          title: 'Ciri Kayu yang Bagus',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Cari kayu yang memiliki tekstur keras dengan cara melihat tingkat kerapatannya. Contoh kayu yang memiliki tekstur keras adalah kayu jati. Kayu seperti ini bisa bertahan lama dari pelapukan. Kemudian, aroma kayu juga bisa menentukan apakah bahan bangunan tersebut berkualitas baik atau tidak. Kayu yang baik memiliki aroma yang manis khas kayu.',
            },
          ],
        },
        {
          title: 'Genting yang Baik dan Sesuai Fungsinya',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Biasanya genting yang masih ramai di pasar adalah genting dari keramik karena dinilai awet, tahan cuaca, warna tidak cepat pudar, dan kuat. Genteng keramik bisa sangat kuat sebab sudah melalui pembakaran dengan suhu yang sangat tinggi.',
            },
          ],
        },
        {
          title: 'Pilih Semen yang Ada Logo SNI',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Karena semen merupakan bahan bangunan hasil pabrikan, artinya bukan mengambil langsung dari alam, maka memilih semen berkualitas lebih mudah, terutama sekarang ada beberapa merek semen yang beredar di pasar.',
            },
            {
              type: SeoContentType.TEXT,
              value:
                'Namun, jika kamu ingin tahu bagaimana memilih semen yang baik, pertama pilih yang bermerek dan memiliki logo SNI. Kemudian, coba pegang semen, jika lembut dan tidak menggumpal, semen tersebut sudah teruji baik.',
            },
            {
              type: SeoContentType.TEXT,
              value:
                'Karena semen berguna untuk perekat, maka bisa dicoba dengan mencampur dengan air dan lihat apakah cukup rekat dan rapat.',
            },
          ],
        },
        {
          title: 'Menentukan Besi yang Kuat',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Sama halnya dengan semen, besi yang kuat dan baik sudah memiliki merek dan berlogo SNI. Jika di bungkus semen bisa terlihat jelas logo SNI, kamu harus meraba besi untuk menemukan logo SNI. Kamu juga bisa melihat dari harganya, semakin baik kualitas besi beton maka harga yang diberikan pun semakin mahal.',
            },
          ],
        },
        {
          title: 'Mengetahui Tanda Keramik Berkualitas',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Ciri-ciri keramik berkualitas diantaranya adalah punya bobot yang berat sebab keramik yang berat artinya kepadatannya cukup baik, tidak ada bekas ketika diusap, tahan goresan, dan ukurannya presisi demi kemudahan pemasangan.',
            },
          ],
        },
      ],
    },
    {
      type: SeoContentType.TITLE,
      value: 'Kisaran Harga Bahan Bangunan 2022',
    },
    {
      type: SeoContentType.TEXT,
      value:
        'Sebagai orang yang akan membuka usaha toko bahan bangunan, maka wajib untuk mengetahui kisaran harga bahan bangunan. Mengutip dari berbagai sumber, berikut ini kisaran harga bahan bangunan untuk tahun 2022:',
    },
    {
      type: SeoContentType.UL,
      value: [
        {
          value: [
            {
              type: SeoContentType.TEXT,
              value: 'Batu kali: Rp 185.000,- sampai Rp 300.000,-',
            },
          ],
        },
        {
          value: [
            {
              type: SeoContentType.TEXT,
              value: 'Batu bata merah: Rp 500,- sampai Rp 900,- (tergantung cara pembakaran dan ukurannya)',
            },
          ],
        },
        {
          value: [
            {
              type: SeoContentType.TEXT,
              value: 'Pasir: kurang lebih Rp 300.000,- (tergantung daerah asal pasir)',
            },
          ],
        },
        {
          value: [
            {
              type: SeoContentType.TEXT,
              value: 'Kayu jati panjang 1-2 meter: Rp 2,5 juta sampai Rp 12 juta (tergantung diameter)',
            },
          ],
        },
        {
          value: [
            {
              type: SeoContentType.TEXT,
              value: 'Kayu mahoni reng dan kaso panjang 2 meter: sekitar Rp 20.000,-',
            },
          ],
        },
        {
          value: [
            {
              type: SeoContentType.TEXT,
              value: 'Kayu mahoni balok panjang 2 meter: sekitar Rp 70.000,-',
            },
          ],
        },
        {
          value: [
            {
              type: SeoContentType.TEXT,
              value: 'Kayu meranti panjang 4 meter: sekitar Rp 40.000,-',
            },
          ],
        },
        {
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Semen: kurang lebih: Rp 60.000,- (untuk semen merek populer, seperti Tiga Roda, Holcim, Padang, dan lainnya yang sudah berlogo SNI)',
            },
          ],
        },
        {
          value: [
            {
              type: SeoContentType.TEXT,
              value: 'Besi beton sudah ber-SNI: Rp 30.000,- sampai Rp 100an (tergantung diameter)',
            },
          ],
        },
        {
          value: [
            {
              type: SeoContentType.TEXT,
              value: 'Pipa: Rp 45.000,- sampai Rp 200.000,- (tergantung ukuran).',
            },
          ],
        },
      ],
    },
    {
      type: SeoContentType.TITLE,
      value: 'Apa Saja yang Harus Disiapkan Jika Akan Buka Usaha Toko Bangunan?',
    },
    {
      type: SeoContentType.TEXT,
      value:
        'Mulai berminat untuk buka usaha toko bangunan? Agar toko kamu mendatangkan pelanggan yang banyak, berikut ini beberapa hal yang harus disiapkan sebelum memulai usaha:',
    },
    {
      type: SeoContentType.OL,
      value: [
        {
          title: 'Memilih Lokasi Toko Bangunan',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Seperti usaha lainnya, lokasi toko bangunan juga penting diperhatikan. Kamu harus mencari tanah yang luas sebab bahan-bahan bangunan bukanlah produk yang kecil. Selain itu, lokasi yang strategis juga sangat menentukan penjualanmu kedepannya.',
            },
          ],
        },
        {
          title: 'Penentuan Harga Bahan Bangunan yang Dijual',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Harga bahan bangunan juga harus dipikir dengan matang, jika perlu kamu melakukan survei untuk melihat harga bahan bangunan dari kompetitor. Jangan sampai harga bahan-bahan yang kamu jual lebih mahal dari toko lain.',
            },
          ],
        },
        {
          title: 'Pemasok Bahan Bangunan',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Mencari pemasok toko gampang-gampang sulit. Pilih pemasok bahan yang bisa terpercaya dan memiliki kualitas bagus. Salah satu cara mudah adalah mengambil pemasok dari Buka Bangunan dari Bukalapak, sebab sudah terjamin terpercaya dan tidak akan kena tipu.',
            },
          ],
        },
        {
          title: 'Strategi Promosi',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Kamu juga harus berpikir strategi toko yang baik. Ada banyak cara untuk promosi usaha bahan bangunan agar banyak orang tahu jika ada toko baru, mulai dari memasang spanduk, memberi brosur, atau menayangkan iklan di media sosial. Memberi promo tertentu saat grand opening juga bisa menarik perhatian pembeli.',
            },
          ],
        },
        {
          title: 'Mencari Pegawai',
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Pegawai cukup penting saat memulai usaha toko bangunan. Berbeda dengan usaha makanan atau toko pakaian, untuk usaha ini kamu harus mencari pegawai yang bisa mengangkat bahan berat, mengemudikan truk, dan mungkin kamu juga butuh seorang kasir.',
            },
          ],
        },
      ],
    },
    {
      type: SeoContentType.TITLE,
      value: 'Cara Menjadi Distributor Bahan Bangunan yang Baik',
    },
    {
      type: SeoContentType.TEXT,
      value:
        'Sebelum menjadi distributor bahan bangunan yang baik, ada beberapa tips dan cara agar pelangganmu merasa puas dengan usaha yang kamu jalankan.',
    },
    {
      type: SeoContentType.UL,
      value: [
        {
          value: [
            {
              type: SeoContentType.TEXT,
              value: 'Pilih lokasi yang mudah diakses dan strategis.',
            },
          ],
        },
        {
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Ketahui kebutuhan pasar saat ini. Kamu bisa menyesuaikan dengan cuaca yang sedang berlangsung, jika musim hujan bisa menambah pasokan pipa.',
            },
          ],
        },
        {
          value: [
            {
              type: SeoContentType.TEXT,
              value: 'Memberi promo dan hadiah untuk pembelian dalam jumlah yang banyak.',
            },
          ],
        },
        {
          value: [
            {
              type: SeoContentType.TEXT,
              value: 'Memberikan produk-produk bahan bangunan yang berkualitas dan tahan dalam segala cuaca.',
            },
          ],
        },
        {
          value: [
            {
              type: SeoContentType.TEXT,
              value:
                'Kualitas pegawai juga harus diperhatikan dan pastikan pegawai yang kamu rekrut memiliki kualitas pelayanan yang baik dan ramah kepada pembeli.',
            },
          ],
        },
        {
          value: [
            {
              type: SeoContentType.TEXT,
              value: 'Beri fasilitas untuk pembeli, seperti sedia mengantar ke tempat tujuan.',
            },
          ],
        },
        {
          value: [
            {
              type: SeoContentType.TEXT,
              value: 'Jujur mengenai harga dan kualitas bahan bangunan.',
            },
          ],
        },
        {
          value: [
            {
              type: SeoContentType.TEXT,
              value: 'Bahan bangunan yang dijual lengkap, sehingga pembeli tidak perlu lagi mencari toko lain.',
            },
          ],
        },
        {
          value: [
            {
              type: SeoContentType.TEXT,
              value: 'Harga yang diberikan toko bahan bangunan di tempatmu terbilang murah.',
            },
          ],
        },
        {
          value: [
            {
              type: SeoContentType.TEXT,
              value: 'Cara terakhir adalah mendapatkan supplier yang tepat.',
            },
          ],
        },
      ],
    },
    {
      type: SeoContentType.TITLE,
      value: 'Dapatkan Bahan Bangunan di BukaBangunan',
    },
    {
      type: SeoContentType.TEXT,
      value:
        'Memulai usaha toko bahan bangunan memang gampang-gampang sulit. Lewat BukaBangunan, kebutuhan bahan-bahan bangunan toko kamu bisa terpenuhi. Kamu bisa mendapatkan semua bahan bangunan yang disebutkan di atas lewat BukaBangunan dengan harga bersahabat dan proses yang mudah.',
    },
    {
      type: SeoContentType.TITLE,
      value: 'Semua Kategori dan Sub Kategori Produk',
    },
    {
      type: SeoContentType.OL,
      value: [
        {
          type: SeoContentType.URL,
          url: 'https://www.bukabangunan.com/c/batu',
          title: 'Batu',
          value: [
            {
              type: SeoContentType.UL,
              value: [
                {
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/batu/batu-bata',
                  title: 'Batu Bata',
                  value: [],
                },
                {
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/batu/batu-split',
                  title: 'Batu Split',
                  value: [],
                },
                {
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/batu/abu-batu',
                  title: 'Abu Batu',
                  value: [],
                },
                {
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/batu/bata-ringan',
                  title: 'Bata Ringan',
                  value: [],
                },
                {
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/batu/batu-lainnya',
                  title: 'Batu Lainnya',
                  value: [],
                },
              ],
            },
          ],
        },
        {
          type: SeoContentType.URL,
          url: 'https://www.bukabangunan.com/c/besi-beton',
          title: 'Besi dan Beton',
          value: [
            {
              type: SeoContentType.UL,
              value: [
                {
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/besi-beton/besi',
                  title: 'Besi',
                  value: [],
                },
                {
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/besi-beton/beton',
                  title: 'Beton',
                  value: [],
                },
              ],
            },
          ],
        },
        {
          type: SeoContentType.URL,
          url: 'https://www.bukabangunan.com/c/cat-perlengkapannya',
          title: 'Cat dan Perlengkapannya',
          value: [
            {
              type: SeoContentType.UL,
              value: [
                {
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/cat-perlengkapannya/cat-pelapis',
                  title: 'Cat Pelapis',
                  value: [],
                },
                {
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/cat-perlengkapannya/plamir',
                  title: 'Plamir',
                  value: [],
                },
                {
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/cat-perlengkapannya/cat-kayu',
                  title: 'Cat Kayu',
                  value: [],
                },
                {
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/cat-perlengkapannya/cat-tembok',
                  title: 'Cat Tembok',
                  value: [],
                },
                {
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/cat-perlengkapannya/cat-semprot',
                  title: 'Cat Semprot',
                  value: [],
                },
                {
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/cat-perlengkapannya/roller-cat',
                  title: 'Roller Cat',
                  value: [],
                },
                {
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/cat-perlengkapannya/kuas-cat',
                  title: 'Kuas Cat',
                  value: [],
                },
                {
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/cat-perlengkapannya/thinner',
                  title: 'Thinner',
                  value: [],
                },
                {
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/cat-perlengkapannya/cat-perlengkapan-lainnya',
                  title: 'Cat & Perlengkapan Lainnya',
                  value: [],
                },
              ],
            },
          ],
        },
        {
          type: SeoContentType.URL,
          url: 'https://www.bukabangunan.com/c/gypsum',
          title: 'Gypsum',
          value: [],
        },
        {
          type: SeoContentType.URL,
          url: 'https://www.bukabangunan.com/c/kamar-mandi',
          title: 'Kamar Mandi',
          value: [
            {
              type: SeoContentType.UL,
              value: [
                {
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/kamar-mandi/keran-air',
                  title: 'Keran Air',
                  value: [],
                },
                {
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/kamar-mandi/shower',
                  title: 'Shower',
                  value: [],
                },
                {
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/kamar-mandi/keran-shower',
                  title: 'Keran Shower',
                  value: [],
                },
                {
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/kamar-mandi/kepala-shower-mandi',
                  title: 'Kepala Shower Mandi',
                  value: [],
                },
                {
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/kamar-mandi/jet-shower-toilet',
                  title: 'Jet Shower Toilet',
                  value: [],
                },
                {
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/kamar-mandi/perlengkapan-lainnya',
                  title: 'Perlengkapan Lainnya',
                  value: [],
                },
              ],
            },
          ],
        },
        {
          type: SeoContentType.URL,
          url: 'https://www.bukabangunan.com/c/kawat',
          title: 'Kawat',
          value: [
            {
              type: SeoContentType.UL,
              value: [
                {
                  title: 'Kawat Seng',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/kawat/kawat-seng',
                  value: [],
                },
                {
                  title: 'Kawat Las',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/kawat/kawat-las',
                  value: [],
                },
                {
                  title: 'Kawat Lainnya',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/kawat/kawat-lainnya',
                  value: [],
                },
              ],
            },
          ],
        },
        {
          type: SeoContentType.URL,
          url: 'https://www.bukabangunan.com/c/kayu',
          title: 'Kayu',
          value: [
            {
              type: SeoContentType.UL,
              value: [
                {
                  title: 'Triplek',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/kayu/triplek',
                  value: [],
                },
                {
                  title: 'Lisplang',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/kayu/lisplang',
                  value: [],
                },
              ],
            },
          ],
        },
        {
          type: SeoContentType.URL,
          url: 'https://www.bukabangunan.com/c/keramik',
          title: 'Keramik',
          value: [],
        },
        {
          type: SeoContentType.URL,
          url: 'https://www.bukabangunan.com/c/lampu',
          title: 'Lampu',
          value: [
            {
              type: SeoContentType.UL,
              value: [
                {
                  title: 'Lampu Darurat',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/lampu/lampu-darurat',
                  value: [],
                },
                {
                  title: 'Bohlam',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/lampu/bohlam',
                  value: [],
                },
                {
                  title: 'Senter',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/lampu/senter',
                  value: [],
                },
                {
                  title: 'Fitting Bohlam',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/lampu/fitting-bohlam',
                  value: [],
                },
              ],
            },
          ],
        },
        {
          type: SeoContentType.URL,
          url: 'https://www.bukabangunan.com/c/ledeng-pipa',
          title: 'Ledeng & Pipa',
          value: [
            {
              type: SeoContentType.UL,
              value: [
                {
                  title: 'Fitting Pipa',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/ledeng-pipa/fitting-pipa',
                  value: [],
                },
                {
                  title: 'Water Heater',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/ledeng-pipa/water-heater',
                  value: [],
                },
                {
                  title: 'Pipa PVC',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/ledeng-pipa/pipa-pvc',
                  value: [],
                },
                {
                  title: 'Selang Shower',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/ledeng-pipa/selang-shower',
                  value: [],
                },
                {
                  title: 'Pembersih Saluran Air',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/ledeng-pipa/pembersih-saluran-air',
                  value: [],
                },
              ],
            },
          ],
        },
        {
          type: SeoContentType.URL,
          url: 'https://www.bukabangunan.com/c/paku',
          title: 'Paku',
          value: [],
        },
        {
          type: SeoContentType.URL,
          url: 'https://www.bukabangunan.com/c/pasir',
          title: 'Pasir',
          value: [
            {
              type: SeoContentType.UL,
              value: [
                {
                  title: 'Pasir Hitam',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/pasir/pasir-hitam',
                  value: [],
                },
                {
                  title: 'Pasir Bangka',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/pasir/pasir-bangka',
                  value: [],
                },
                {
                  title: 'Pasir Rangkas',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/pasir/pasir-rangkas',
                  value: [],
                },
                {
                  title: 'Pasir Lainnya',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/pasir/pasir-lainnya',
                  value: [],
                },
              ],
            },
          ],
        },
        {
          type: SeoContentType.URL,
          url: 'https://www.bukabangunan.com/c/perekat-dan-lainnya',
          title: 'Perekat & Lainnya',
          value: [
            {
              type: SeoContentType.UL,
              value: [
                {
                  title: 'Isolasi',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perekat-dan-lainnya/isolasi',
                  value: [],
                },
                {
                  title: 'Lem Pipa',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perekat-dan-lainnya/lem-pipa',
                  value: [],
                },
                {
                  title: 'Lem Material',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perekat-dan-lainnya/lem-material',
                  value: [],
                },
                {
                  title: 'Lainnya',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perekat-dan-lainnya/lainnya',
                  value: [],
                },
              ],
            },
          ],
        },
        {
          type: SeoContentType.URL,
          url: 'https://www.bukabangunan.com/c/perkakas-listrik',
          title: 'Perkakas Listrik',
          value: [
            {
              type: SeoContentType.UL,
              value: [
                {
                  title: 'Mata Gerinda',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perkakas-listrik/mata-gerinda',
                  value: [],
                },
                {
                  title: 'Mesin Gerinda',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perkakas-listrik/mesin-gerinda',
                  value: [],
                },
                {
                  title: 'Mata Bor',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perkakas-listrik/mata-bor',
                  value: [],
                },
                {
                  title: 'Mesin Bor',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perkakas-listrik/mesin-bor',
                  value: [],
                },
                {
                  title: 'Gergaji',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perkakas-listrik/gergaji',
                  value: [],
                },
                {
                  title: 'Perkakas Listrik Lainnya',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perkakas-listrik/perkakas-listrik-lainnya',
                  value: [],
                },
              ],
            },
          ],
        },
        {
          type: SeoContentType.URL,
          url: 'https://www.bukabangunan.com/c/perkakas-mesin',
          title: 'Perkakas Mesin',
          value: [],
        },
        {
          type: SeoContentType.URL,
          url: 'https://www.bukabangunan.com/c/perkakas-tangan',
          title: 'Perkakas Tangan',
          value: [
            {
              type: SeoContentType.UL,
              value: [
                {
                  title: 'Kunci Sock',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perkakas-tangan/kunci-sock',
                  value: [],
                },
                {
                  title: 'Kunci Pas',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perkakas-tangan/kunci-pas',
                  value: [],
                },
                {
                  title: 'Sambungan Sock',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perkakas-tangan/sambungan-sock',
                  value: [],
                },
                {
                  title: 'Kunci Roda',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perkakas-tangan/kunci-roda',
                  value: [],
                },
                {
                  title: 'Kunci Nepel',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perkakas-tangan/kunci-nepel',
                  value: [],
                },
                {
                  title: 'Kunci Inggris',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perkakas-tangan/kunci-inggris',
                  value: [],
                },
                {
                  title: 'Tool Kit Set',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perkakas-tangan/tool-kit-set',
                  value: [],
                },
                {
                  title: 'Gunting Potong',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perkakas-tangan/gunting-potong',
                  value: [],
                },
                {
                  title: 'Gergaji',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perkakas-tangan/gergaji',
                  value: [],
                },
                {
                  title: 'Kunci L',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perkakas-tangan/kunci-l',
                  value: [],
                },
                {
                  title: 'Sendok Semen',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perkakas-tangan/sendok-semen',
                  value: [],
                },
                {
                  title: 'Mata Soket',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perkakas-tangan/mata-soket',
                  value: [],
                },
                {
                  title: 'Kunci Ring',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perkakas-tangan/kunci-ring',
                  value: [],
                },
                {
                  title: 'Obeng',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perkakas-tangan/obeng',
                  value: [],
                },
                {
                  title: 'Tang',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perkakas-tangan/tang',
                  value: [],
                },
                {
                  title: 'Lain-Lain',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perkakas-tangan/lain-lain',
                  value: [],
                },
              ],
            },
          ],
        },
        {
          type: SeoContentType.URL,
          url: 'https://www.bukabangunan.com/c/perlengkapan-las',
          title: 'Perlengkapan Las',
          value: [],
        },
        {
          type: SeoContentType.URL,
          url: 'https://www.bukabangunan.com/c/perlengkapan-listrik',
          title: 'Perlengkapan Listrik',
          value: [
            {
              type: SeoContentType.UL,
              value: [
                {
                  title: 'Saklar',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perlengkapan-listrik/saklar',
                  value: [],
                },
                {
                  title: 'Stop Kontak',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perlengkapan-listrik/stop-kontak',
                  value: [],
                },
                {
                  title: 'Kabel Roll',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perlengkapan-listrik/kabel-roll',
                  value: [],
                },
                {
                  title: 'Baterai',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perlengkapan-listrik/baterai',
                  value: [],
                },
                {
                  title: 'Steker',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perlengkapan-listrik/steker',
                  value: [],
                },
                {
                  title: 'Listrik Lainnya',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/perlengkapan-listrik/listrik-lainnya',
                  value: [],
                },
              ],
            },
          ],
        },
        {
          type: SeoContentType.URL,
          url: 'https://www.bukabangunan.com/c/pintu-dan-jendela',
          title: 'Pintu & Jendela',
          value: [],
        },
        {
          type: SeoContentType.URL,
          url: 'https://www.bukabangunan.com/c/semen',
          title: 'Semen',
          value: [
            {
              type: SeoContentType.UL,
              value: [
                {
                  title: 'Mortar',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/semen/mortar',
                  value: [],
                },
                {
                  title: 'Keramik Lantai',
                  type: SeoContentType.URL,
                  url: 'https://www.bukabangunan.com/c/semen/keramik-lantai',
                  value: [],
                },
              ],
            },
          ],
        },
        {
          type: SeoContentType.URL,
          url: 'https://www.bukabangunan.com/c/lain-lain',
          title: 'Lain-Lain',
          value: [],
        },
      ],
    },
  ],
}
