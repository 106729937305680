













import { BlFlexContainerMv, BlFlexItemAv, BlTextAv } from '@bukalapak/bazaar-mweb'
import Vue from 'vue'
import PlayStoreButton from '~/components/organisms/LandingPage/PlayStoreButton/index.vue'

export default Vue.extend({
  name: 'LandingDownload',
  components: {
    BlFlexContainerMv,
    BlFlexItemAv,
    BlTextAv,
    PlayStoreButton,
  },
  props: {
    screen: {
      type: String,
      default: '',
    },
  },
  methods: {
    trackClick() {
      if (this.screen) {
        this.$tracker.track('app_action', {
          screen_name: this.screen,
          section_name: 'DownloadApp_Section',
          action_type: 'click',
          action_source: 'GooglePlayStoreIcon_Button',
          action_attribute: JSON.stringify({}),
        })
      }
    },
  },
})
