

















import { BlButtonAv, BlTextAv } from '@bukalapak/bazaar-mweb'
import VLazyImage from 'v-lazy-image/v2'
import Vue, { PropType } from 'vue'
import { MainGlobalEvents } from '~/constants/pages/main-global-events'
import type { CategoryProduct } from '~/types/component/landing-product'

export default Vue.extend({
  name: 'LandingProductCard',
  components: {
    BlTextAv,
    BlButtonAv,
    VLazyImage,
  },
  props: {
    product: {
      type: Object as PropType<CategoryProduct>,
      required: true,
    },
    screen: {
      type: String,
      default: '',
    },
    section: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      imagePlaceholder: require('~/assets/images/loader-thumbnail.svg'),
      defaultImage: 'https://s2.bukalapak.com/attachment/237412/placeholder.png',
      isError: false,
    }
  },
  computed: {
    image(): string {
      return this.isError || !this.product.image ? this.defaultImage : this.product.image
    },
  },
  methods: {
    handleDownload() {
      if (this.screen && this.section) {
        this.$tracker.track('app_action', {
          screen_name: this.screen,
          section_name: this.section,
          action_type: 'click',
          action_source: 'Product_card',
          action_attribute: JSON.stringify({}),
        })
      }
      this.$nuxt.$emit(MainGlobalEvents.HEADER_DOWNLOAD_EVENT)
    },
  },
})
