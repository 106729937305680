

























































































































import { BlFlexContainerMv, BlFlexItemAv, BlTextAv } from '@bukalapak/bazaar-mweb'
import Vue, { PropType } from 'vue'
import { SeoContents, SeoContentType } from '~/types/model/seo'
import { sanitizedContent } from '~/utils/xss-sanitize'

export default Vue.extend({
  name: 'SeoContent',
  components: {
    BlFlexContainerMv,
    BlFlexItemAv,
    BlTextAv,
  },
  props: {
    contents: {
      type: Object as PropType<SeoContents>,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
      SeoContentType,
    }
  },
  computed: {
    sanitizedSEO(): string {
      return sanitizedContent(this.contents.pageDescripiton || '')
    },
  },
})
