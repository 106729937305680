import xss, { escapeAttrValue, whiteList } from 'xss'
export const sanitizedContent = (content: string): string => {
  const modifiedWhiteList = { ...whiteList }
  const knownDefaultTags = ['a', 'button', 'p', 'b', 'i', 'u', 'strong', 'em', 'ul', 'ol', 'li', 'dt', 'dd', 'small']
  knownDefaultTags.forEach(tag => {
    modifiedWhiteList[tag] = ['style', 'class']
  })
  if (modifiedWhiteList.a) {
    modifiedWhiteList.a.push('href')
  }
  return xss(content, {
    whiteList: modifiedWhiteList,
    onTagAttr(tag, name, value, isWhiteAttr) {
      if (!isWhiteAttr) return
      if (tag === 'a') {
        if (name === 'rel') return ''
        if (name === 'href') {
          return `href="${escapeAttrValue(value)}" rel="noopener,noreferrer" target="_blank"`
        }
      }
    },
  })
}
