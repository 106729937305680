























import { BlTextAv } from '@bukalapak/bazaar-mweb'
import BlIllustrationAv from '@bukalapak/bazaar-visual/dist/components/BlIllustrationAv'
import Vue from 'vue'

export default Vue.extend({
  name: 'LandingUsp',
  components: {
    BlTextAv,
    BlIllustrationAv,
  },
  data() {
    return {
      usps: [
        {
          title: '100% aman!',
          description: 'Belanja bahan bangunan jadi lebih tenang, transaksi 100% aman didukung oleh Mitra Bukalapak.',
          tnc: '',
          illustration: require('~/assets/images/illustration/img_medium_bukabangunan_secure.png'),
        },
        {
          title: 'Stok selalu ada',
          description: 'Menyediakan bahan bangunan terlengkap dengan jaminan barang sampai maksimal 3x24 jam*.',
          tnc: '*tergantung pada musim dan kondisi cuaca',
          illustration: require('~/assets/images/illustration/img_medium_bukabangunan_stock_ready.png'),
        },
        {
          title: 'Harga terbaik & kompetitif ',
          description: 'Sedia bahan bangunan dengan harga terjangkau.',
          tnc: '',
          illustration: require('~/assets/images/illustration/img_medium_box_discount.png'),
        },
      ],
      imagePlaceholder: require('~/assets/images/loader-thumbnail.svg'),
    }
  },
})
