










import Vue from 'vue'

export default Vue.extend({
  name: 'LandingBrand',
  data() {
    return {
      brands: [
        {
          name: 'HANNOCHS',
          image: require('~/assets/images/landing-page/brand/HANNOCHS.png'),
        },
        {
          name: 'BROCO',
          image: require('~/assets/images/landing-page/brand/BROCO.png'),
        },
        {
          name: 'TEKIRO',
          image: require('~/assets/images/landing-page/brand/TEKIRO.png'),
        },
        {
          name: 'MARSELUS STEEL',
          image: require('~/assets/images/landing-page/brand/MARSELUS STEEL.png'),
        },
        {
          name: 'aplus',
          image: require('~/assets/images/landing-page/brand/aplus.png'),
        },
        {
          name: 'MORTAR UTAMA',
          image: require('~/assets/images/landing-page/brand/MORTAR UTAMA.png'),
        },
        {
          name: 'BOSCH',
          image: require('~/assets/images/landing-page/brand/BOSCH.png'),
        },
        {
          name: 'DYNAMIX',
          image: require('~/assets/images/landing-page/brand/DYNAMIX.png'),
        },
      ],
    }
  },
})
