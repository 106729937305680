export type SeoMeta = {
  title: string
  description: string
}

export enum SeoContentType {
  TEXT = 'text',
  TITLE = 'title',
  OL = 'ol',
  UL = 'ul',
  URL = 'url',
}

export type SeoContentList = {
  type?: SeoContentType
  title?: string
  url?: string
  value: SeoContentList[] | string
}

export type SeoContent = {
  type: SeoContentType
  value: string | SeoContentList[]
}

export type SeoContents = {
  meta?: SeoMeta
  title: string
  list: SeoContent[]
  pageDescripiton?: string
}

export type SeoProperties = {
  id: number
  page_key: string
  meta_title: string
  meta_description: string
  page_title: string
  page_description: string
  schema: string
  destination_url: string
}
