




























import { BlFlexContainerMv, BlFlexItemAv, BlTextAv } from '@bukalapak/bazaar-mweb'
import Vue from 'vue'
import PlayStoreButton from '~/components/organisms/LandingPage/PlayStoreButton/index.vue'

type BannerBackground = {
  image: string
  width: string
  height: string
}

export default Vue.extend({
  name: 'LandingBanner',
  components: {
    BlFlexContainerMv,
    BlFlexItemAv,
    BlTextAv,
    PlayStoreButton,
  },
  props: {
    screen: {
      type: String,
      default: '',
    },
  },
  computed: {
    backgroundImage(): BannerBackground {
      return this.$viewport.match('mobile')
        ? {
            image: require('~/assets/images/landing-page/bukabangunan_banner_mobile.png'),
            width: '360',
            height: '360',
          }
        : {
            image: require('~/assets/images/landing-page/bukabangunan_banner.png'),
            width: '1300',
            height: '480',
          }
    },
  },
})
