




import Vue from 'vue'

export default Vue.extend({
  name: 'LandingTestimonials',
  components: {
    Desktop: /* istanbul ignore next */ () => import(/* webpackChunkName: 'testimonials-desktop' */ './Desktop.vue'),
    Mobile: /* istanbul ignore next */ () => import(/* webpackChunkName: 'testimonials-mobile' */ './Mobile.vue'),
  },
  computed: {
    isMobileTablet(): boolean {
      return this.$viewport.isLessThan('desktop').valueOf()
    },
  },
})
